import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  VStack,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { format, parseISO, isValid } from 'date-fns';

const EventDialog = ({ isOpen, onClose, onSaveItem, item, groups, onAddGroup, mode }) => {
  const [editedItem, setEditedItem] = useState(item || { content: '', start: '', end: '', style: 'background-color: #97c2fc;', group: '' });
  const toast = useToast();

  useEffect(() => {
    if (item) {
      setEditedItem({
        ...item,
        start: item.start instanceof Date ? format(item.start, 'yyyy-MM-dd') : 
               (typeof item.start === 'string' ? item.start : ''),
        end: item.end instanceof Date ? format(item.end, 'yyyy-MM-dd') : 
             (typeof item.end === 'string' ? item.end : ''),
      });
    } else {
      setEditedItem({ content: '', start: '', end: '', style: 'background-color: #97c2fc;', group: '' });
    }
  }, [item]);

  if (!isOpen) return null;

  const handleItemChange = (e) => {
    const { name, value } = e.target;
    if (name === 'group') {
      if (value === 'new') {
        const groupName = prompt("Enter new group name:");
        if (groupName) {
          onAddGroup({ content: groupName, defaultColor: '#000000' });
          setEditedItem({ ...editedItem, group: groupName });
        }
      } else {
        setEditedItem({ ...editedItem, group: value });
      }
    } else {
      setEditedItem({ ...editedItem, [name]: value });
    }
  };

  const handleColorChange = (color) => {
    setEditedItem({ ...editedItem, style: `background-color: ${color};` });
  };

  const handleSubmit = () => {
    if (!editedItem.content || !editedItem.start) {
      toast({
        title: "Error",
        description: "Please fill in all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const parsedStart = parseISO(editedItem.start);
    const parsedEnd = editedItem.end ? parseISO(editedItem.end) : null;

    if (!isValid(parsedStart) || (editedItem.end && !isValid(parsedEnd))) {
      toast({
        title: "Error",
        description: "Invalid date format. Please use YYYY-MM-DD.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const itemToSave = {
      ...editedItem,
      start: parsedStart,
      end: parsedEnd,
      className: `timeline-item ${editedItem.group}`,  // Add a class based on the group
    };

    onSaveItem(itemToSave);
    onClose();
  };

  const colors = [
    "#FF5733", "#C70039", "#900C3F", "#2196F3", "#000000",
    "#4A148C", "#1A237E", "#006064", "#1B5E20", "#3E2723"
  ];

  const currentColor = editedItem.style.split(': ')[1].slice(0, -1);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{mode === 'add' ? 'Add New Event' : 'Edit Event'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Event Name</FormLabel>
              <Input name="content" value={editedItem.content} onChange={handleItemChange} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Start Date</FormLabel>
              <Input type="date" name="start" value={editedItem.start} onChange={handleItemChange} />
            </FormControl>
            <FormControl>
              <FormLabel>End Date</FormLabel>
              <Input type="date" name="end" value={editedItem.end} onChange={handleItemChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Group</FormLabel>
              <Select name="group" value={editedItem.group} onChange={handleItemChange}>
                <option value="">Select a group</option>
                {groups && groups.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.content}
                  </option>
                ))}
                <option value="new">Add new group</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Color</FormLabel>
              <SimpleGrid columns={5} spacing={2}>
                {colors.map((color) => (
                  <Button
                    key={color}
                    aria-label={color}
                    background={color}
                    height="20px"
                    width="20px"
                    padding={0}
                    minWidth="unset"
                    borderRadius={3}
                    _hover={{ background: color }}
                    onClick={() => handleColorChange(color)}
                    border={color === currentColor ? '2px solid white' : 'none'}
                  />
                ))}
              </SimpleGrid>
              <Text mt={2} fontSize="sm">Selected color: {currentColor}</Text>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            {mode === 'add' ? 'Add Event' : 'Save Changes'}
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EventDialog;
