import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Timeline, DataSet } from "vis-timeline/standalone";
import "vis-timeline/styles/vis-timeline-graph2d.css";
import { parseISO, format, formatDate, isDate } from 'date-fns';
import axios from 'axios';
import {
  Button,
  Input,
  Box,
  VStack,
  HStack,
  Text,
  useToast,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Image,
  FormControl,
  FormLabel,
  Tooltip,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Textarea,
  Progress,
} from '@chakra-ui/react';
import { AddIcon, AttachmentIcon, DeleteIcon, DownloadIcon, SettingsIcon, CalendarIcon, InfoIcon, RepeatIcon } from '@chakra-ui/icons';
import AboutDialog from './AboutDialog';
import EventDialog from './EventDialog';
import SettingsDialog from './SettingsDialog';


const AddGroupDialog = ({ isOpen, onClose, onAddGroup }) => {
  const [newGroup, setNewGroup] = useState({ content: '' });
  const toast = useToast();

  const handleGroupChange = (e) => {
    const { name, value } = e.target;
    setNewGroup({ ...newGroup, [name]: value });
  };

  const handleAddGroup = () => {
    if (!newGroup.content) {
      toast({
        title: "Invalid input",
        description: "Please enter a group name",
        status: "error",
        duration: 3000,
        isClosable: true,
        variant: "solid",
        position: "top",
        containerStyle: {
          maxWidth: "100%",
        },
        buttonTheme: "dark",
      });
      return;
    }

    onAddGroup(newGroup);
    setNewGroup({ content: '' });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Add New Group</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="center">
            <Input
              type="text"
              name="content"
              value={newGroup.content}
              onChange={handleGroupChange}
              placeholder="Group Name"
            />
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button colorScheme="blue" mr={3} onClick={handleAddGroup}>
            Add Group
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};



const ClearConfirmationDialog = ({ isOpen, onClose, onConfirm }) => {
  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Clear Timeline
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? This will remove all events and groups from the timeline.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={onConfirm} ml={3}>
              Clear
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const SaveConfirmationDialog = ({ isOpen, onClose, onConfirm, beforeData, afterData }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent maxWidth="90vw" maxHeight="90vh">
        <ModalHeader>Save Changes</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch" height="70vh">
            <Text fontWeight="bold">Before:</Text>
            <Box flex="1" overflowY="auto">
              <pre>{JSON.stringify(beforeData, null, 2)}</pre>
            </Box>
            <Text fontWeight="bold">After:</Text>
            <Box flex="1" overflowY="auto">
              <pre>{JSON.stringify(afterData, null, 2)}</pre>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm}>
            Save Changes
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ensureDateString = (dateInput) => {
  if (isDate(dateInput)) {
    return format(dateInput, 'yyyy-MM-dd');
  } else if (typeof dateInput === 'string') {
    return dateInput;
  } else {
    throw new Error('Invalid date input');
  }
};

const InteractiveTimeline = () => {
  const toast = useToast();
  const [items, setItems] = useState(new DataSet());
  const [groupsState, setGroupsState] = useState(new DataSet());
  const [settings, setSettings] = useState({
    timelineTitle: 'New Timeline',
    zoomLevel: 1, // Default zoom level
  });
  const [currentDate, setCurrentDate] = useState(new Date());
  const { isOpen: isSettingsOpen, onOpen: onSettingsOpen, onClose: onSettingsClose } = useDisclosure();
  const [beforeData, setBeforeData] = useState(null);
  const setNewItem = useCallback((item) => {
    // This function is used elsewhere, so we keep it as a no-op function
    console.log('setNewItem called with:', item);
  }, []);
  const [afterData, setAfterData] = useState(null);
  const { isOpen: isSaveConfirmOpen, onOpen: onSaveConfirmOpen, onClose: onSaveConfirmClose } = useDisclosure();
  const { isOpen: isAboutOpen, onOpen: onAboutOpen, onClose: onAboutClose } = useDisclosure();
  const { isOpen: isUploadOpen, onOpen: onUploadOpen, onClose: onUploadClose } = useDisclosure();
  const [jsonContent, setJsonContent] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [aiPrompt, setAiPrompt] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/data.json');
        const text = await response.text();
        let data;
        try {
          data = JSON.parse(text);
        } catch (parseError) {
          console.error('Error parsing JSON:', parseError);
          toast({
            title: "Error loading data",
            description: `Invalid JSON: ${parseError.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        console.log('Loaded data:', data); // Log the loaded data
        const formattedItems = data.items.map(item => ({
          ...item,
          start: parseISO(item.start),
          end: item.end ? parseISO(item.end) : null
        }));
        
        // Add the new "test" item
        formattedItems.push({
          id: 'test-item',
          content: 'test',
          start: parseISO('2000-01-01'),
          end: parseISO('2024-01-01'),
          group: data.groups[0].id // Assuming there's at least one group
        });
        
        console.log('Formatted items:', formattedItems); // Log the formatted items
        console.log('Groups:', data.groups); // Log the groups
        
        setItems(new DataSet(formattedItems));
        setGroupsState(new DataSet(data.groups));
        setSettings({
          timelineTitle: data.settings?.timelineTitle || 'New Timeline',
          defaultCenterDate: data.settings?.defaultCenterDate || null,
        });
      } catch (error) {
        console.error('Error loading initial data:', error);
        toast({
          title: "Error loading initial data",
          description: "Please check the console for more details",
          status: "error",
          duration: 3000,
          isClosable: true,
          variant: "solid",
          position: "top",
          containerStyle: {
            maxWidth: "100%",
          },
          buttonTheme: "dark",
        });
      }
    };

    fetchData();
  }, [toast]);

  useEffect(() => {
    if (timelineRef.current && settings.defaultCenterDate) {
      const centerDate = parseISO(settings.defaultCenterDate);
      timelineRef.current.moveTo(centerDate);
    }
  }, [settings.defaultCenterDate]);

  // Separate effect for logging after state updates
  useEffect(() => {
    console.log('Items after setting:', items.get()); // Log items after setting
    console.log('Groups after setting:', groupsState.get()); // Log groups after setting
  }, [items, groupsState]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      setUploadProgress(0);
      const reader = new FileReader();
      reader.onload = (e) => {
        setJsonContent(e.target.result);
        setIsUploading(false);
        setUploadProgress(100);
      };
      reader.onprogress = (e) => {
        if (e.lengthComputable) {
          const progress = (e.loaded / e.total) * 100;
          setUploadProgress(progress);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleJsonContentChange = (event) => {
    setJsonContent(event.target.value);
  };

  const handleJsonUpload = () => {
    try {
      const data = JSON.parse(jsonContent);
      loadTimelineData(data);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      const errorMessage = error instanceof SyntaxError
        ? `JSON Syntax Error: ${error.message} at line ${getErrorLineNumber(jsonContent, error)}`
        : "Please make sure the content is valid JSON";
      toast({
        title: "Error loading data",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const loadTimelineData = (data) => {
    const formattedItems = data.items.map(item => ({
      ...item,
      start: parseISO(ensureDateString(item.start)),
      end: item.end ? parseISO(ensureDateString(item.end)) : null
    }));
    const newSettings = {
      timelineTitle: data.settings?.timelineTitle || 'New Timeline',
      defaultCenterDate: data.settings?.defaultCenterDate || null,
      zoomLevel: data.settings?.zoomLevel || 1
    };
    
    // Update state
    setItems(new DataSet(formattedItems));
    setGroupsState(new DataSet(data.groups));
    setSettings(newSettings);
    
    // Force timeline update
    if (timelineRef.current) {
      timelineRef.current.setItems(new DataSet(formattedItems));
      timelineRef.current.setGroups(new DataSet(data.groups));
      
      // Use setTimeout to ensure the DOM has updated before fitting
      setTimeout(() => {
        // Reset zoom and fit all items
        timelineRef.current.fit({animation: true});
        
        // If there's a specific defaultCenterDate, move to it
        if (newSettings.defaultCenterDate) {
          const centerDate = parseISO(newSettings.defaultCenterDate);
          timelineRef.current.moveTo(centerDate, {animation: true});
        }
      }, 0);
    }
    
    toast({
      title: "Data loaded successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
      variant: "solid",
      position: "top",
      containerStyle: {
        maxWidth: "100%",
      },
      buttonTheme: "dark",
    });
    onUploadClose();
  };

  const handleAiGenerate = async () => {
    setIsGenerating(true);
    try {
      const response = await axios.post('https://generate-timeline-worker.fasa-root.workers.dev/generate_timeline', {
        prompt: aiPrompt
      });
      const generatedData = response.data;
      loadTimelineData(generatedData);
    } catch (error) {
      console.error('Error generating timeline:', error);
      toast({
        title: "Error generating timeline",
        description: "An error occurred while generating the timeline. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const getErrorLineNumber = (jsonString, error) => {
    const lines = jsonString.split('\n');
    const errorPosition = error.message.match(/position (\d+)/);
    if (errorPosition) {
      const position = parseInt(errorPosition[1], 10);
      let lineNumber = 1;
      let currentPosition = 0;
      for (const line of lines) {
        if (currentPosition + line.length >= position) {
          return lineNumber;
        }
        currentPosition += line.length + 1; // +1 for the newline character
        lineNumber++;
      }
    }
    return 'unknown';
  };
  const timelineRef = useRef(null);
  const containerRef = useRef(null);
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isAddGroupOpen, onOpen: onAddGroupOpen, onClose: onAddGroupClose } = useDisclosure();
  const { isOpen: isClearConfirmOpen, onOpen: onClearConfirmOpen, onClose: onClearConfirmClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);

  const favoriteColors = useMemo(() => {
    const colorCounts = {};
    if (items && typeof items.get === 'function') {
      items.get().forEach(item => {
        if (item.style) {
          const color = item.style.split(': ')[1]?.slice(0, -1);
          if (color) {
            colorCounts[color] = (colorCounts[color] || 0) + 1;
          }
        }
      });
    }
    return Object.entries(colorCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10)
      .map(([color]) => color);
  }, [items]);

  useEffect(() => {
    if (containerRef.current) {
      const options = {
        start: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        end: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        orientation: 'top',
        selectable: true,
        editable: {
          add: true,
          updateTime: true,
          updateGroup: true,
          remove: true,
          overrideItems: false
        },
        stack: true,
        stackSubgroups: true,
        horizontalScroll: true,
        zoomKey: 'ctrlKey',
        zoomMin: 1000 * 60 * 60 * 24,  // One day
        zoomMax: 1000 * 60 * 60 * 24 * 31 * 12 * 10,  // 10 years
        snap: null,
        multiselect: true,
        onMove: function(item, callback) {
          const updatedItem = items.get(item.id);
          updatedItem.start = item.start;
          updatedItem.end = item.end;
          updatedItem.group = item.group;
          items.update(updatedItem);
          callback(item);
        },
        onRemove: function(item, callback) {
          items.remove(item.id);
          callback(item);
        }
      };

      if (!timelineRef.current) {
        timelineRef.current = new Timeline(containerRef.current, items, groupsState, options);
        timelineRef.current.on('doubleClick', function(properties) {
          if (properties.item) {
            const item = items.get(properties.item);
            setSelectedItem(item);
            onEditOpen();
          } else if (properties.time) {
            setNewItem({
              content: '',
              start: formatDate(properties.time, 'yyyy-MM-dd'),
              end: '',
              style: 'background-color: #000000;',
              group: ''
            });
            onAddOpen();
          }
        });

        timelineRef.current.on('rangechanged', function(properties) {
          const centerTime = (properties.start.getTime() + properties.end.getTime()) / 2;
          setCurrentDate(new Date(centerTime));
        });

        timelineRef.current.on('select', function(properties) {
          if (properties.items.length > 0) {
            const item = items.get(properties.items[0]);
            setSelectedItem(item);
          } else {
            setSelectedItem(null);
          }
        });

        timelineRef.current.on('itemover', function(properties) {
          // You can add tooltip functionality here
        });

        timelineRef.current.on('timechanged', function(properties) {
          // This event is fired when the current time is changed
        });


      }

      // Always set options, groups, and items
      timelineRef.current.setOptions(options);
      timelineRef.current.setGroups(groupsState);
      timelineRef.current.setItems(items);

      // Force a redraw of the timeline
      timelineRef.current.redraw();
    }
  }, [items, groupsState, onEditOpen, onAddOpen, favoriteColors, setNewItem, setSelectedItem]);

  const handleResetZoom = useCallback(() => {
    if (timelineRef.current) {
      timelineRef.current.fit();
    }
  }, []);

  useEffect(() => {
    if (timelineRef.current) {
      const options = {
        start: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        end: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        orientation: 'top',
        editable: {
          add: true,
          updateTime: true,
          updateGroup: true,
          remove: false
        },
        stack: true,
        snap: null, // This will snap items to whole days
      };

      timelineRef.current.setOptions(options);
      timelineRef.current.setGroups(groupsState);
      timelineRef.current.setItems(items);
      timelineRef.current.redraw();
    }
  }, [items, groupsState]);

  // Separate effect for resetting zoom
  useEffect(() => {
    handleResetZoom();
  }, [handleResetZoom]);

  useEffect(() => {
    // Reset zoom after timeline initialization
    const resetZoomTimeout = setTimeout(() => {
      handleResetZoom();
    }, 0);

    return () => clearTimeout(resetZoomTimeout);
  }, [handleResetZoom]);

  const handleSaveItem = (item) => {
    const formattedItem = {
      ...item,
      start: parseISO(item.start),
      end: item.end ? parseISO(item.end) : null
    };

    if (item.id) {
      // Editing existing item
      items.update(formattedItem);
    } else {
      // Adding new item
      items.add(formattedItem);
    }

    // Force timeline to redraw
    if (timelineRef.current) {
      timelineRef.current.redraw();
    }
  };

  const handleClearAll = () => {
    onClearConfirmOpen();
  };

  const confirmClearAll = () => {
    window.location.reload();
    onClearConfirmClose();
  };

  const handleSaveToJson = () => {
    const currentWindow = timelineRef.current.getWindow();
    const zoomLevel = (currentWindow.end - currentWindow.start) / (24 * 60 * 60 * 1000); // Calculate zoom level in days
    const newData = {
      settings: { ...settings, zoomLevel },
      items: items.get(),
      groups: groupsState.get()
    };
    setBeforeData(JSON.parse(localStorage.getItem('timelineData') || '{}'));
    setAfterData(newData);
    onSaveConfirmOpen();
  };

  const confirmSaveToJson = () => {
    const jsonString = JSON.stringify(afterData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'timeline_data.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    localStorage.setItem('timelineData', jsonString);
    onSaveConfirmClose();
    toast({
      title: "Data saved to JSON",
      status: "success",
      duration: 3000,
      isClosable: true,
      variant: "solid",
      position: "top",
      containerStyle: {
        maxWidth: "100%",
      },
      buttonTheme: "dark",
    });
  };

  const handleSaveSettings = (newSettings) => {
    setSettings(newSettings);
    toast({
      title: "Settings saved",
      status: "success",
      duration: 3000,
      isClosable: true,
      variant: "solid",
      position: "top",
      containerStyle: {
        maxWidth: "100%",
      },
      buttonTheme: "dark",
    });
  };

  const handleAddGroup = (newGroup) => {
    const maxId = Math.max(...groupsState.getIds(), 0);
    const id = maxId + 1;
    groupsState.add({ ...newGroup, id, defaultColor: '#000000' });
    setGroupsState(new DataSet(groupsState.get()));
  };

  return (
    <Box height="100vh" width="100vw" position="relative" bg="#252a31" data-testid="timeline-component">
      <Flex as="nav" align="center" justify="space-between" wrap="wrap" padding="0.3rem" bg="#2c323b">
        <Flex align="center" mr={3}>
          <Image src="/logo_205_i.png" alt="Logo" boxSize="50px" mr={2} />
          <Text fontSize="xl" fontWeight="bold">Time<span style={{color: "#a1d242"}}>Lime</span></Text>
        </Flex>
        <Flex align="center">
          <HStack spacing={1} mr={3}>
          <Tooltip label="Add a new event to the timeline" aria-label="Add Event Tooltip">
            <Button size="xs" onClick={onAddOpen} colorScheme="blue" leftIcon={<CalendarIcon boxSize={3} />} aria-label="Add Event">
              Add Event
            </Button>
          </Tooltip>
          <Tooltip label="Add a new group to the timeline" aria-label="Add Group Tooltip">
            <Button size="xs" onClick={onAddGroupOpen} colorScheme="green" leftIcon={<AddIcon boxSize={3} />}>
              Add Group
            </Button>
          </Tooltip>
          <Tooltip label="Import a JSON to populate the timeline" aria-label="Upload Tooltip">
            <IconButton
              icon={<AttachmentIcon boxSize={3} />}
              onClick={onUploadOpen}
              aria-label="Upload JSON"
              size="xs"
              colorScheme="purple"
            />
          </Tooltip>
          <Tooltip label="Save the current timeline data to a JSON file" aria-label="Save to JSON Tooltip">
            <IconButton
              icon={<DownloadIcon boxSize={3} />}
              onClick={handleSaveToJson}
              aria-label="Save to JSON"
              size="xs"
              colorScheme="teal"
            />
          </Tooltip>
          <Tooltip label="Clear all data and reset the timeline" aria-label="Clear All Tooltip">
            <IconButton
              icon={<DeleteIcon boxSize={3} />}
              onClick={handleClearAll}
              aria-label="Clear All"
              size="xs"
              colorScheme="red"
            />
          </Tooltip>
          <Tooltip label="Open settings" aria-label="Settings Tooltip">
            <IconButton
              icon={<SettingsIcon boxSize={3} />}
              onClick={onSettingsOpen}
              aria-label="Settings"
              size="xs"
            />
          </Tooltip>
          <Tooltip label="Reset zoom" aria-label="Reset Zoom Tooltip">
            <IconButton
              icon={<RepeatIcon boxSize={3} />}
              onClick={handleResetZoom}
              aria-label="Reset Zoom"
              size="xs"
            />
          </Tooltip>
          <Tooltip label="About" aria-label="About Tooltip">
            <IconButton
              icon={<InfoIcon boxSize={3} />}
              onClick={onAboutOpen}
              aria-label="About"
              size="xs"
            />
          </Tooltip>
          </HStack>
        </Flex>
      </Flex>
      <VStack spacing={4} align="stretch" height="calc(100% - 80px)" padding={4}>
        <Flex align="center" justify="center" mb={4} direction="column">
          <Text
            fontSize="2xl"
            fontWeight="bold"
            textAlign="center"
            color="white"
          >
            {settings.timelineTitle}
          </Text>
          <Text
            fontSize="lg"
            textAlign="center"
            color="white"
          >
            {formatDate(currentDate, 'MMMM d, yyyy')}
          </Text>
        </Flex>
        <Box 
          flex={1} 
          ref={containerRef} 
          position="relative" 
          sx={{
            '.vis-timeline': {
              color: 'white',
            },
            '.vis-item': {
              color: 'black',
              border: 'black',
              background: 'orange',
            },
            '.vis-label': {
              color: 'white',
            },
            '.vis-labelset .vis-label': {
              color: 'white',
              height: '40px', // Set a custom height for groups
              lineHeight: '40px', // Align text vertically
            },
            '.vis-time-axis .vis-text': {
              color: 'white',
            },
            '.vis-time-axis .vis-grid.vis-odd': {
              background: 'rgba(255, 255, 255, 0.05)',
            },
            '.vis-time-axis .vis-grid.vis-even': {
              background: 'rgba(255, 255, 255, 0.02)',
            },
          }}
        />
      </VStack>
      <EventDialog 
        isOpen={isAddOpen || isEditOpen}
        onClose={isAddOpen ? onAddClose : onEditClose}
        onSaveItem={handleSaveItem}
        item={isEditOpen ? selectedItem : null}
        groups={groupsState.get()}
        onAddGroup={handleAddGroup}
        mode={isAddOpen ? 'add' : 'edit'}
      />
      <AddGroupDialog 
        isOpen={isAddGroupOpen} 
        onClose={onAddGroupClose} 
        onAddGroup={handleAddGroup}
      />
      <SettingsDialog
        isOpen={isSettingsOpen}
        onClose={onSettingsClose}
        settings={settings}
        onSaveSettings={handleSaveSettings}
      />
      <ClearConfirmationDialog
        isOpen={isClearConfirmOpen}
        onClose={onClearConfirmClose}
        onConfirm={confirmClearAll}
      />
      <SaveConfirmationDialog
        isOpen={isSaveConfirmOpen}
        onClose={onSaveConfirmClose}
        onConfirm={confirmSaveToJson}
        beforeData={beforeData}
        afterData={afterData}
      />
      <AboutDialog isOpen={isAboutOpen} onClose={onAboutClose} />
      <Modal isOpen={isUploadOpen} onClose={onUploadClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Timeline importer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Upload JSON File</FormLabel>
                <Input type="file" accept=".json" onChange={handleFileUpload} />
                {isUploading && (
                  <Progress value={uploadProgress} size="sm" colorScheme="blue" mt={2} />
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Or Paste JSON Content</FormLabel>
                <Textarea
                  value={jsonContent}
                  onChange={handleJsonContentChange}
                  placeholder="Paste your JSON content here"
                  height="200px"
                />
              </FormControl>
              <FormControl>
                <FormLabel>AI Timeline Generator</FormLabel>
                <Input
                  value={aiPrompt}
                  onChange={(e) => setAiPrompt(e.target.value)}
                  placeholder="Enter a prompt for AI timeline generation"
                />
                <Button
                  mt={2}
                  colorScheme="purple"
                  onClick={handleAiGenerate}
                  isLoading={isGenerating}
                  loadingText="Generating"
                  isDisabled={!aiPrompt}
                >
                  Generate Timeline
                </Button>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button 
              colorScheme="blue" 
              mr={3} 
              onClick={handleJsonUpload}
              isDisabled={isUploading || (!jsonContent && uploadProgress !== 100)}
            >
              Upload
            </Button>
            <Button variant="ghost" onClick={onUploadClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default InteractiveTimeline;
